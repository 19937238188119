import React, { useRef, useEffect, useState } from 'react';
import './Login.css';

const Login = ({ onLoginSuccess, checkMissingContent, USER_SECRET_CODE, ADMIN_SECRET_CODE }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const inputRef = useRef(null);

    const validatePassword = (password) => {
        if (password === USER_SECRET_CODE) {
            onLoginSuccess();
            checkMissingContent();
            setError('');
        } else if (password === ADMIN_SECRET_CODE) {
            onLoginSuccess(new Date('2025-01-01'));
            setError('');
        } else {
            setError('Contraseña incorrecta. Inténtelo de nuevo.');
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        validatePassword(password);
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <div className="login-form-group">
                    <p>🔐 Introduzca su contraseña para ver el contenido</p>
                    <input
                        type='password'
                        ref={inputRef}
                        className="login-form-control"
                        placeholder='Contraseña...'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    {error && <div className="login-error-message">{error}</div>}
                </div>
                <button type="submit" className="login-submit-button">Acceder</button>
            </form>
        </div>
    );
};

export default Login;
