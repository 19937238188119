import React, { useEffect, useState } from 'react'
import './welcomeModal.css'

export default function WelcomeModal({ closeWelcomeModal }) {
    const clickClose = () => {
        closeWelcomeModal();
    }

    return (
        <div id="elementoSuperior" class="container">
            <div class="row">
                <div class="col-5 col-md-4"></div>
                <div class="col-8 col-md-4">
                    <div class="modalContainer modal-content">
                        <div class="modal-header">
                            <h2 class="modal-title textCenter" id="exampleModalLiveLabel">🎁 ¡Bienvenida a tu regalo! 🎁</h2>
                        </div>
                        <div class="modal-body">
                            <p>Todavía no puedo creer que esto esté sucediendo. 😱</p>
                            <p>He dedicado mucho tiempo y amor en construir lo que vas a ver a continuación. 🥰</p>
                            <p>Como te habrás dado cuenta, esto, es una página web hecha para ti. 👨🏻‍🦰➡️👧🏻</p>
                            <p>🤩¡¿Que guay no?!🤩</p>
                            <p>Calma, deja que te explique...</p>
                            <hr/>
                            <p>La gracia es que cada día, vas a tener una imagen elegida por mi que va a hacerte recordar nuestros mejores momentos juntos. 📸👨🏻‍🦰👧🏻</p>
                            <p>Y a demás podrás leer un pequeño comentario sobre lo que significa esa imagen para mi. 💕</p>
                            <p>Espero que te gusten y te acompañen durante nuestro próximo año. 📆</p>
                            <hr/>
                            <p class="textRight">~ Te quiere, Brian 💋</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" onClick={clickClose}>Cerrar</button>
                        </div>
                    </div>
                </div>
                <div class="col-5 col-md-4"></div>
            </div>
        </div>
    )
}