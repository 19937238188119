import React, { useState, useCallback } from 'react';
import './App.css';
import ImageLoader from './components/imageLoader/ImageLoader.js';
import Login from './components/login/Login';
import emailjs from 'emailjs-com';
import comments from './assets/comments.json';

const App = () => {
    const [isCorrectSecret, setIsCorrectSecret] = useState(false);
    const [today, setToday] = useState(new Date());

    const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
    const USER_SECRET_CODE = process.env.REACT_APP_USER_SECRET_CODE;
    const ADMIN_SECRET_CODE = process.env.REACT_APP_ADMIN_SECRET_CODE;

    const sendEmail = useCallback((body) => {
        const templateParams = {
            subject: '⚠️ Poco contenido',
            body,
        };

        emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, templateParams, EMAILJS_USER_ID)
            .then((response) => {
                console.log('Correo enviado', response.status, response.text);
            })
            .catch((err) => {
                console.error('Error al enviar el correo', err);
            });
    }, [EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_USER_ID]);

    const checkMissingContent = useCallback(() => {
        let firstNullCommentDate = new Date();
        for (const item of comments) {
            if (!item.comment) {
                firstNullCommentDate = new Date(item.date);
                break;
            }
        }

        const currentDate = new Date();
        const differenceInTime = firstNullCommentDate - currentDate;
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        console.log(`Sin comentario el día ${firstNullCommentDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })} (Margen ${Math.floor(differenceInDays)} días)`);
        if (differenceInDays <= 2) {
            sendEmail(`Sin comentario el día ${firstNullCommentDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })} (Margen ${Math.floor(differenceInDays)} días)`);
        }
    }, [sendEmail]);

    const handleLoginSuccess = (adminDate = new Date()) => {
        setToday(adminDate);
        setIsCorrectSecret(true);
    };

    return (
        <div className="App">
            <header className="App-header">
                {!isCorrectSecret && (
                    <Login
                        onLoginSuccess={handleLoginSuccess}
                        checkMissingContent={checkMissingContent}
                        USER_SECRET_CODE={USER_SECRET_CODE}
                        ADMIN_SECRET_CODE={ADMIN_SECRET_CODE}
                    />
                )}
                {isCorrectSecret && <ImageLoader today={today} />}
            </header>
        </div>
    );
};

export default App;
